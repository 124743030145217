import * as React from "react";
import * as Sentry from "@sentry/react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";

import GlobalFooter from "../shared/GlobalFooter";
import Logo from "../../assets/images/logo512.png";

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            component="img"
            src={Logo}
            sx={{ display: { xs: "none", md: "flex" }, height: "2rem" }}
            alt="logo"
          />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="https://www.advancedpension.com/home"
            sx={{
              mx: 1,
              display: { xs: "none", md: "flex" },
              fontWeight: 500,
              color: "inherit",
              textDecoration: "none",
            }}
          >
            Advanced Pension
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="small"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            ></Menu>
          </Box>
          <Box
            component="img"
            src={Logo}
            sx={{ display: { xs: "flex", md: "none" }, height: "2rem" }}
            alt="logo"
          />
          <Typography
            variant="subtitle2"
            noWrap
            component="a"
            href="https://www.advancedpension.com/home"
            sx={{
              mr: 2,
              ml: 1,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontWeight: 500,
              color: "inherit",
              textDecoration: "none",
            }}
          >
            Advanced Pension
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}></Box>
          <Box sx={{ flexGrow: 0, display: "flex" }}>
            <Button
              href="/login"
              onClick={handleCloseNavMenu}
              sx={{ my: 2, display: "block" }}
            >
              Login
            </Button>
            <Button
              href="/register"
              onClick={handleCloseNavMenu}
              sx={{ my: 2, display: "block" }}
              variant="contained"
            >
              Signup
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

function BrochurePage() {
  return (
    <div>
      <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
        <ResponsiveAppBar />
        <Box sx={{ height: "80vh", width: "100vw" }}>
        <iframe
          title="brochure"
          style={{
              height: "100%",
              width: "100%",
              border: "none",
              margin: 0,
              padding: 0,
              display: "block"
          }}
          src="https://pensionapp-assets.s3.us-west-2.amazonaws.com/wbvl/index.html#p=1">
        </iframe>
        </Box>
        <GlobalFooter />
      </Sentry.ErrorBoundary>
    </div>
  );
}

export default BrochurePage;
